<template>
    <ul class="ul-social-list">
        <li>
            <a class="twitter link" href="https://twitter.com/thewingofhelp" rel="nofollow" target="_blank">
                <svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2812 5.50789L11.3316 6.33835L10.4922 6.23666C7.43686 5.84685 4.76765 4.52491 2.50134 2.30471L1.39336 1.20309L1.10798 2.01659C0.503625 3.83004 0.889738 5.74516 2.1488 7.03322C2.8203 7.74503 2.66921 7.84672 1.51088 7.42302C1.10798 7.28744 0.755438 7.18575 0.721863 7.23659C0.60435 7.35523 1.00725 8.8975 1.32621 9.50763C1.76269 10.355 2.65243 11.1855 3.6261 11.677L4.44869 12.0668L3.47501 12.0837C2.53491 12.0837 2.50134 12.1007 2.60206 12.4566C2.93781 13.5582 4.26403 14.7276 5.74133 15.2361L6.78215 15.592L5.87563 16.1343C4.53263 16.9139 2.9546 17.3546 1.37658 17.3885C0.621138 17.4054 0 17.4732 0 17.5241C0 17.6935 2.04808 18.6426 3.23999 19.0155C6.81573 20.1171 11.063 19.6426 14.2526 17.7613C16.5189 16.4224 18.7852 13.7616 19.8428 11.1855C20.4136 9.8127 20.9844 7.30439 20.9844 6.10107C20.9844 5.32146 21.0347 5.21978 21.9748 4.28763C22.5288 3.7453 23.0492 3.15211 23.15 2.98263C23.3178 2.66062 23.3011 2.66062 22.4449 2.94874C21.018 3.45718 20.8165 3.38939 21.5216 2.62672C22.042 2.08439 22.6631 1.1014 22.6631 0.813283C22.6631 0.762439 22.4113 0.847179 22.1259 0.999712C21.8238 1.16919 21.1523 1.42341 20.6486 1.57595L19.7421 1.86406L18.9195 1.30478C18.4663 0.999712 17.8283 0.660751 17.4926 0.559063C16.6364 0.32179 15.327 0.355686 14.5548 0.626855C12.4563 1.38952 11.1301 3.35549 11.2812 5.50789Z" />
                </svg>
            </a>
        </li>
        <li>
            <a class="telegram link" href="https://t.me/ukraines_angels_channel" rel="nofollow" target="_blank">
                <svg width="24" height="20" viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.362483 9.24577C2.81774 7.89334 5.55848 6.76459 8.1193 5.63007C12.5249 3.77181 16.948 1.94576 21.4157 0.245724C22.285 -0.0439451 23.8469 -0.327226 24 0.960971C23.9162 2.7844 23.5712 4.59715 23.3345 6.4099C22.7339 10.3963 22.0398 14.3691 21.3629 18.3423C21.1297 19.6658 19.4717 20.351 18.4109 19.504C15.8614 17.7819 13.2924 16.0766 10.7755 14.3146C9.95104 13.4769 10.7156 12.2738 11.4519 11.6756C13.5517 9.60624 15.7786 7.8481 17.7686 5.67181C18.3054 4.37548 16.7193 5.468 16.1962 5.80273C13.3215 7.78369 10.5173 9.88558 7.48649 11.6266C5.93838 12.4788 4.13403 11.7505 2.58662 11.2749C1.19916 10.7005 -0.833964 10.1218 0.362343 9.24586L0.362483 9.24577Z"/>
                </svg>
            </a>
        </li>
        <li>
            <a class="facebook link" href="https://www.facebook.com/ukrainesangels" rel="nofollow" target="_blank">
                <svg width="13" height="27" viewBox="0 0 13 27" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.50195 26.1115V13.0542H12.1063L12.584 8.5546H8.50195L8.50807 6.30251C8.50807 5.12894 8.61958 4.50012 10.3052 4.50012H12.5585V0H8.95358C4.62353 0 3.09946 2.18279 3.09946 5.85357V8.55511H0.400391V13.0547H3.09946V26.1115H8.50195Z" />
                </svg>
            </a>
        </li>
        <li>
          <a class="instagram link" href="https://www.instagram.com/the_wing_of_help/" rel="nofollow" target="_blank">
          </a>
        </li>
    </ul>
</template>