<template>
  <div>
    <header-component/>
    <mint-component/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import MintComponent from "@/components/MintComponent";

export default {
  components: {
    HeaderComponent,
    MintComponent
  }
}
</script>